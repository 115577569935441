export const getUxtFromUrl = (href: string) => {
  if (!href) return '';
  // фильтруем location search на то что там есть Uxt,
  // а не что-то другое типа ?product=virtual-datacenter или utm
  const regexUxt = new RegExp(/uxt=./);

  const hrefWithUxt = regexUxt.test(href) ? href : null;
  if (!hrefWithUxt) return '';

  const uxtParam = regexUxt.exec(hrefWithUxt);
  if (!uxtParam || !uxtParam?.[0]) return '';

  const variant = uxtParam[0].slice(-1);

  return variant;
};

export const getUxtFromLc = () => {
  if (typeof window == 'undefined') return '';

  const variant =
    getUxtFromUrl(window.location.href) === ''
      ? window.localStorage.getItem('uxt')
      : getUxtFromUrl(window.location.href);

  return variant;
};
